*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
}

html body {
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
}
